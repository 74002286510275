body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  overflow: hidden;
  position: relative;
}
body {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 400;
}
.content .MuiTableCell-root {
  padding: 8px 15px !important;
  border-bottom: 1px solid #ddd;
  word-break: break-all;
  font-size: 13px;
  p {
    margin: 0 !important;
  }
}
.content .MuiTableCell-root,
.content .MuiButton-root {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}
.content .MuiButton-root {
  font-weight: 500;
}
.content .MuiTableCell-root {
  font-size: 12px;
  font-weight: 500;
}
.content .MuiTableCell-head {
  font-weight: 600;
  font-size: 13px;
}
.content .MuiFormLabel-root,
.content .MuiInputBase-root {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}
.content .card-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 19px;
  text-transform: uppercase;
  padding: 0 20px !important;
  &.paddingt20 {
    padding-top: 20px !important;
  }
}
.content .play-card small {
  font-weight: 600;
}
.content .makeStyles-formControl-104 {
  margin: 0;
}
.sidenav .navigation .nav-item button,
.sidenav .navigation .nav-item {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}
.sidenav .navigation .nav-item {
  margin-bottom: 7px;
}
.status-buttons {
  .MuiButton-root {
    background: #c1ffc4;
    font-size: 12px;
    border-radius: 23px;
    float: none;
    margin: 0;
    &.disabled-btn {
      background: #ffcdbd;
      font-size: 12px;
      border-radius: 23px;
      float: none;
    }
  }
  
}
.quill {
  margin: 25px 0 0;
}
.content .MuiButton-containedSecondary,
.MuiDialogActions-root .MuiButton-containedSecondary {
  background: #c7c7c7;
  &:hover,
  &:focus,
  &:active {
    background: #a4a4a4;
  }
}
.MuiDialogActions-root .MuiButton-containedSecondary {
  padding: 9px 20px;
  min-width: 110px;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}
.content .button-row {
  margin-top: 30px;
  .MuiButton-root {
    padding: 9px 20px;
    min-width: 110px;
    font-size: 14px;
    font-weight: 600;
  }
  .MuiButton-containedSecondary {
    margin-left: 15px;
  }
}
.MuiDialogContentText-root {
  margin-bottom: 0 !important;
}
.MuiDialogTitle-root {
  padding: 0 0 10px;
  margin: 0 0 10px;
  border-bottom: 1px solid #ddd;
  h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
  }
}
.MuiDialogContent-root {
  overflow: auto !important;
  padding: 0 5px !important;
}
.MuiDialogActions-root {
  padding: 20px 25px !important;
  margin: 10px 0 0;
  border-top: 1px solid #ddd;
}
.report-popup {
  font-family: 'Montserrat', sans-serif;
  .MuiGrid-root {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    color: #666;
    font-size: 13px;
    strong {
      color: #999;
      margin-bottom: 0;
    }
    a.textlink {
      text-decoration: underline;
      color: #333;
      font-weight: 600;
    }
  }
}
.dashboard-boxes-wrap {
  //padding: 30px;
  margin-top: 30px;
  >div {
    margin-bottom: 30px;
    &:nth-child(2) {
      .card-title {
        padding-top: 20px !important;
      }
    }
  }
}
.content {
  padding: 30px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  color: rgba(255, 0, 221, 0.863);
  font-size: 16px;
  // color: rgba(255, 162, 0, 0.842);
}
