body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  overflow: hidden;
  position: relative; }

body {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 400; }

.content .MuiTableCell-root {
  padding: 8px 15px !important;
  border-bottom: 1px solid #ddd;
  word-break: break-all;
  font-size: 13px; }
  .content .MuiTableCell-root p {
    margin: 0 !important; }

.content .MuiTableCell-root,
.content .MuiButton-root {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400; }

.content .MuiButton-root {
  font-weight: 500; }

.content .MuiTableCell-root {
  font-size: 12px;
  font-weight: 500; }

.content .MuiTableCell-head {
  font-weight: 600;
  font-size: 13px; }

.content .MuiFormLabel-root,
.content .MuiInputBase-root {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500; }

.content .card-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 19px;
  text-transform: uppercase;
  padding: 0 20px !important; }
  .content .card-title.paddingt20 {
    padding-top: 20px !important; }

.content .play-card small {
  font-weight: 600; }

.content .makeStyles-formControl-104 {
  margin: 0; }

.sidenav .navigation .nav-item button,
.sidenav .navigation .nav-item {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500; }

.sidenav .navigation .nav-item {
  margin-bottom: 7px; }

.status-buttons .MuiButton-root {
  background: #c1ffc4;
  font-size: 12px;
  border-radius: 23px;
  float: none;
  margin: 0; }
  .status-buttons .MuiButton-root.disabled-btn {
    background: #ffcdbd;
    font-size: 12px;
    border-radius: 23px;
    float: none; }

.quill {
  margin: 25px 0 0; }

.content .MuiButton-containedSecondary,
.MuiDialogActions-root .MuiButton-containedSecondary {
  background: #c7c7c7; }
  .content .MuiButton-containedSecondary:hover, .content .MuiButton-containedSecondary:focus, .content .MuiButton-containedSecondary:active,
  .MuiDialogActions-root .MuiButton-containedSecondary:hover,
  .MuiDialogActions-root .MuiButton-containedSecondary:focus,
  .MuiDialogActions-root .MuiButton-containedSecondary:active {
    background: #a4a4a4; }

.MuiDialogActions-root .MuiButton-containedSecondary {
  padding: 9px 20px;
  min-width: 110px;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif; }

.content .button-row {
  margin-top: 30px; }
  .content .button-row .MuiButton-root {
    padding: 9px 20px;
    min-width: 110px;
    font-size: 14px;
    font-weight: 600; }
  .content .button-row .MuiButton-containedSecondary {
    margin-left: 15px; }

.MuiDialogContentText-root {
  margin-bottom: 0 !important; }

.MuiDialogTitle-root {
  padding: 0 0 10px;
  margin: 0 0 10px;
  border-bottom: 1px solid #ddd; }
  .MuiDialogTitle-root h2 {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600; }

.MuiDialogContent-root {
  overflow: auto !important;
  padding: 0 5px !important; }

.MuiDialogActions-root {
  padding: 20px 25px !important;
  margin: 10px 0 0;
  border-top: 1px solid #ddd; }

.report-popup {
  font-family: 'Montserrat', sans-serif; }
  .report-popup .MuiGrid-root {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    color: #666;
    font-size: 13px; }
    .report-popup .MuiGrid-root strong {
      color: #999;
      margin-bottom: 0; }
    .report-popup .MuiGrid-root a.textlink {
      text-decoration: underline;
      color: #333;
      font-weight: 600; }

.dashboard-boxes-wrap {
  margin-top: 30px; }
  .dashboard-boxes-wrap > div {
    margin-bottom: 30px; }
    .dashboard-boxes-wrap > div:nth-child(2) .card-title {
      padding-top: 20px !important; }

.content {
  padding: 30px; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  color: rgba(255, 0, 221, 0.863);
  font-size: 16px; }
